import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogClose, MatDialogRef } from "@angular/material/dialog";
import { first } from "rxjs";
import { SafeHtmlPipe } from "../../../../pipes/safe-html.pipe";
import { NgIf } from "@angular/common";
import { MatIcon } from "@angular/material/icon";
import { MatTooltip } from "@angular/material/tooltip";
import { MatIconButton } from "@angular/material/button";
import { CdkDrag, CdkDragHandle } from "@angular/cdk/drag-drop";
import { NgxTolgeeModule } from "@tolgee/ngx";
import { ProtocolService } from "../../../../services/protocol.service";

@Component({
	selector: "app-protocol-light-viewer",
	templateUrl: "./protocol-light-viewer.component.html",
	styleUrls: ["./protocol-light-viewer.component.scss"],
	imports: [
		CdkDrag,
		MatIconButton,
		CdkDragHandle,
		MatTooltip,
		MatIcon,
		NgIf,
		MatDialogClose,
		SafeHtmlPipe,
		NgxTolgeeModule,
	],
})
export class ProtocolLightViewerComponent implements OnInit {
	widthSize = "600px";
	body = "";

	constructor(
		@Inject(MAT_DIALOG_DATA) private protocolId: number,
		private protocolService: ProtocolService,
		private dialogRef: MatDialogRef<ProtocolLightViewerComponent>
	) {}

	ngOnInit(): void {
		this.protocolService
			.getDraftProtocol(this.protocolId)
			.pipe(first())
			.subscribe((content) => {
				this.body = content.content;
			});
	}

	updateModalWidth(): void {
		if (this.widthSize !== "600px") {
			this.widthSize = "600px";
		} else {
			this.widthSize = "400px";
		}
		this.dialogRef.updateSize(this.widthSize);
	}
}
